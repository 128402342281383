import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { enableTools } from "constants";

// Auth routes
const OrganizationsPage = lazy(() => import("pages/Auth/SelectOrganization"));
const LoginPage = lazy(() => import("pages/Auth/Login"));
const ForgotPassword = lazy(() => import("pages/Auth/ForgetPassword"));
const ResetPassword = lazy(() => import("pages/Auth/ResetPassword"));
const Onboard = lazy(() => import("pages/Auth/SelfSignupFlow"));
const TnCPage = lazy(() => import("pages/Auth/TnCPage"));
const PrivacyPolicy = lazy(() => import("pages/Auth/PrivacyPolicy"));

// App routes
const UserDashboardPage = lazy(
  () => import("pages/UserDashboard/UserDashboard"),
);
const OrganizationsDashboardPage = lazy(
  () => import("pages/OrganizationsDashboard/OrganizationsDashboardPage"),
);
const CostBucketPage = lazy(() => import("pages/costBucket/Costbucket"));
const SharedBucketPage = lazy(
  () => import("pages/sharedCostBucket/SharedBucket"),
);
const IntegrationWizard = lazy(
  () => import("pages/IntegrationWizard/IntegrationWizard"),
);
const CostSourcePage = lazy(() => import("pages/CostSource/CostSourcePage"));
const UserDetailPage = lazy(() => import("pages/UserDetail/UserDetailPage"));
const BudgetApproval = lazy(
  () => import("pages/BudgetApprovals/BudgetApprovalPage"),
);
const Reports = lazy(() => import("pages/Reports/Reports"));
const CostSource = lazy(() => import("pages/CostSourceById/CostSource"));
const Optimization = lazy(() => import("pages/Optimization/Optimization"));
const Administration = lazy(
  () => import("pages/Administration/Administration"),
);
const BudgetPlan = lazy(() => import("pages/BudgetPlanById/BudgetPlan"));
const Tools = lazy(() => import("pages/tools/Tools"));
const Setup = lazy(() => import("pages/Setup/Setup"));

// Open routes
const ComparePricing = lazy(() => import("pages/freeTools/ComparePricing"));
const CalculatePricing = lazy(() => import("pages/freeTools/CalculatePricing"));

export const authRoutes = [
  {
    title: "Home",
    path: "/",
    element: <Navigate to="/login" />,
  },
  {
    path: "/login",
    element: <LoginPage />,
    title: "Login",
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword />,
    title: "Forgot Password",
  },
  {
    path: "/resetpassword/:resetToken",
    element: <ResetPassword />,
    title: "Reset Password",
  },
  {
    path: "/signup",
    element: <Onboard />,
    title: "Signup",
  },
  {
    path: "/tnc",
    element: <TnCPage />,
    title: "Terms & conditions",
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
    title: "Privacy Policies",
  },
];

export const protectedRoutesWithLayout = [
  {
    path: "/userDetails",
    element: <UserDetailPage />,
    title: "User Details",
  },
  {
    path: "/myBuckets",
    element: <UserDashboardPage />,
    title: "My Buckets",
  },
  {
    path: "/orgDashboard",
    element: <OrganizationsDashboardPage />,
    title: "Organization Dashboard",
  },
  {
    path: "/costbucket/:id",
    element: <CostBucketPage />,
    title: "Cost Bucket",
  },
  {
    path: "/sharedcostbucket/:id",
    element: <SharedBucketPage />,
    title: "Shared Cost Bucket",
  },
  {
    path: "/integration/new",
    element: <IntegrationWizard />,
    title: "Integration",
  },
  {
    path: "/integration",
    element: <CostSourcePage />,
    title: "Integration",
  },
  {
    path: "/costsource/:id",
    element: <CostSource />,
    title: "Cost Source",
  },
  {
    path: "/budgetApprovals",
    element: <BudgetApproval />,
    title: "Budget Approvals",
  },
  {
    path: "/budgetplan/:id",
    element: <BudgetPlan />,
    title: "Budget Plan",
  },
  {
    path: "/optimization",
    element: <Optimization />,
    title: "Optimization",
  },
  {
    path: "/administration",
    element: <Administration />,
    title: "Administration",
  },
  {
    path: "/insight",
    element: <Reports />,
    title: "Insight",
  },
  enableTools && {
    path: "/tools",
    element: <Tools />,
    title: "Tools",
  },
  {
    path: "/setup",
    element: <Setup />,
    title: "Setup",
  },
];

export const protectedRoutesWithoutLayout = [
  {
    path: "/organizations",
    element: <OrganizationsPage />,
    title: "Organizations",
  },
];

export const openRoutes = [
  {
    path: "/free-tools/compare-price",
    element: <ComparePricing />,
    title: "Compare Price",
  },
  {
    path: "/free-tools/calculate-price",
    element: <CalculatePricing />,
    title: "Calculate Price",
  },
];
